body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.poppins{font-family:"Poppins"}.wha-icon{width:15vw;max-width:100px;height:15vw;max-height:100px;z-index:100;position:fixed;bottom:10px;right:10px;overflow:hidden}.wha-icon>a{width:10%;height:10%}.wha-icon>a>img{width:100%;height:100%}.wha-icon :hover{transform:scale(1.2)}
/*  Animation for drawing a circle */
@keyframes drawCircle {
  /* At the end of the animation, the stroke-dashoffset is set to 0 */
  0% {
    stroke-dashoffset: 1304;
  }
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 1304;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Styles for the circle */
.Circle {
  /* Defines the length of the dashes in the stroke */
  stroke-dasharray: 1304;
  /* Defines the distance from the start of the stroke to the beginning of the dash */
  stroke-dashoffset: 1304;
  /* Applies the animation to draw the circle */
  animation: drawCircle 2s ease-in-out infinite, rotate 2.5s linear infinite;
  animation-direction: alternate;
  transform-origin: center;
}

/* Fade in animation for the fire */
@keyframes flicker {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* Styles for the fire */
.Fire {
  /* Opacity is initially set to 0 */
  opacity: 0;
  /* Applies the fade in animation with a delay of 1.2s */
  animation: flicker 0.2s ease-out 0.4s infinite;
}

/* Shake animation for the rocket */
@keyframes shake {
  0%,
  100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-4px, -4px);
  }
  75% {
    transform: translate(4px, 4px);
  }
}

/* Styles for the rocket */
.Rocket {
  /* Applies the shake animation with a duration of 1s */
  animation: shake 0.7s ease-out 0s infinite;
}

.loaderContainer {
  background-color: transparent;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styles for the tgLoader */
.tgLoader {
  /* Position the tgLoader relative to its normal position */
  position: relative;
  /* Set the width to 15% of the parent element */
  width: 200px;
  /* Cent3r the contents both horizontally and vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  /* Center the text */
  text-align: center;
}

/* Styles for the svg */
.tgLoader svg {
  width: 100%;
  height: auto;
}

.container{width:100%;z-index:101;position:fixed;bottom:10px;display:flex;justify-content:center}.container .content-content{width:95%;padding:20px;background-color:#f0fff8;display:flex;flex-direction:row;justify-content:space-between;align-items:center;gap:20px;border:2px solid #000}.container .content-content .left-container{width:70%;display:flex;justify-content:center;align-items:center;gap:30px}.container .content-content .left-container>img{width:90px;height:90px}.container .content-content .left-container .content{width:90%;font-size:1.2em}.container .content-content>button{height:40px}@media screen and (max-width: 800px){.container .content-content{width:90%;flex-direction:column}.container .content-content .left-container{align-items:start;justify-content:start;width:100%}.container .content-content .left-container .content{width:100%;font-size:1.1em;margin:0}.container .content-content .left-container>img{width:80px;height:80px}}@media screen and (max-width: 700px){.container .content-content{width:100%;flex-direction:column}.container .content-content .left-container{align-items:start;justify-content:start;width:100%}.container .content-content .left-container .content{width:100%;font-size:1.1em;margin:0}.container .content-content .left-container>img{width:80px;height:80px}.container .content-content>button{height:40px;width:55% !important;padding-left:10px;padding-right:10px}}@media screen and (max-width: 400px){.container .content-content{width:85%;flex-direction:column}.container .content-content .left-container{flex-direction:column;align-items:center;justify-content:start;width:100%}.container .content-content .left-container .content{width:100%;font-size:1.1em;text-align:center;margin:0}.container .content-content .left-container>img{width:80px;height:80px}}

.toast {
    font-weight: bold;
    font-size: large;
    background-color: black;
    color: white;
  }
